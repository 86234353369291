<template>
  <div>
    <div class="mx-auto mb-2">
        <b-row class="justify-content-end align-items-center">
          
          <!-- Headers Buttons -->
          <b-col sm="5" class="d-flex justify-content-end">
            <div class="custom-search d-flex justify-content-end">
              <b-button
                variant="primary"
                size="md"
                @click="$emit('fetchAssureursDetails')"
              >
              <feather-icon icon="RefreshCwIcon"/>
              </b-button>

              <b-button
                v-b-toggle.sidebar-assureur
                variant="primary"
                size="md"
                class="ml-2"  
              >
                <feather-icon icon="PlusIcon" />
                <span class="align-middle"> Ajouter Assureur</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
    </div>
    <!-- Table -->
    <vue-good-table
      :columns="columns"
      :rows="assureurs"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'custom-class',
        selectionText: 'lignes sélectionnées',
        clearSelectionText: 'Effacer la sélection',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'id'">
          <span class="cursor-pointer">
            <b-button
              v-b-toggle.sidebar-assureur
              size="20"
              class="mr-50"
              variant="none"
              @click="functionid(props.row.id, props.row.nom_com_assureur)"
            >
              <feather-icon size="20" icon="EditIcon" class="mr-50" />
            </b-button>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
          <b-col md="6" lg="5" xxl="3">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Affichage 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> de {{ props.total }} entrées </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div class="text-center align-middle" slot="emptystate">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
    <b-sidebar
      id="sidebar-assureur"
      ref="sideBarAssureur"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
    >
      <template>
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            Enregistrer un Assureur
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer ref="ModifyAssureur">
          <b-form class="p-2" @reset.prevent="resetForm"   @submit.prevent="ModifyAssureur(onSubmit)">
            <b-overlay :show="showLoading" no-wrap />
            <b-form-group label="Dénomination*" label-for="nom_com_assureur">
              <validation-provider
                #default="{ errors }"
                name="Dénomination"
                rules="required"
              >
                <b-form-input
                  v-model="assureur.nom_com_assureur"
                  id="nom_com_assureur"
                  placeholder="Entre dénomination"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                  >{{ errors[0] }}</b-form-invalid-feedback
                >
              </validation-provider>
            </b-form-group>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-2"
                variant="primary"
                @click="ModifyAssureur"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>
  
  <script>
import { VueGoodTable } from "vue-good-table";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";

import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import {
  BSidebar,
  VBToggle,
  BPagination,
  BRow,
  BCol,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BFormInput,
  BFormGroup,
  BForm,
  BFormSelect,
  BFormInvalidFeedback,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
export default {
  components: {
    // VGT
    VueGoodTable,
    // BV
    BPagination,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    VBToggle,
    BSidebar,
    BOverlay,
    BFormInput,
    BFormGroup,
    BForm,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContentVue,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BInputGroup,
  },
  props: {
    assureurs: {
      type: Array,
      default: null,
    },
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },

  data() {
    return {
      pageLength: 10,
      dir: false,
      searchTerm: "",
      columns: [
        {
          field: "nom_com_assureur",
          label: "Dénomination",
          sortable: true,
          filterable: true,
          thClass:
            "border-0 text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
          filterOptions: { enabled: true, placeholder: "Dénomination" },
        },
        {
          field: "id",
          label: "Actions",
          sortable: false,
          filterable: false,
          thClass:
            "border-0 text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
      ],
      showLoading: false,
      assureur: {
        id: null,
        nom_com_assureur: null,
      },
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },

    hide() {
      this.$refs.sideBarAssureur.hide();
      this.assureur.id = null;
      this.assureur.nom_com_assureur = null;
    },
    functionid(id, nom_com_assureur) {
      this.assureur.id = id;
      this.assureur.nom_com_assureur = nom_com_assureur;
    },
    ModifyAssureur() {
      this.showLoading = true;
      this.$http
        .post("assureurs/updateDenomination", this.assureur)
        .then((res) => {
          if (res.data.success) {
            this.$emit('fetchAssureursDetails');
            this.messageToast(
              "Dénomination a été enregistré avec succès",
              "Succès",
              "success"
            );
          } else {
            this.messageToast(
              "Erreur lors de changement de mot de passe.",
              "Erreur",
              "error"
            );
          }
        })
        .catch((err) => {
          this.messageToast(
            "Erreur lors de changement de mot de passe.",
            "Erreur",
            "error"
          );
        })
        .finally(() => {
          this.hide();
          this.showLoading = false;
        });
    },
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>